<template>
  <div class="menu-wrap" v-click-outside="closeSubMenu">
    <div class="main-menu">
      <div class="text-center">
        <RouterLink to="/" class="w-100 h-100">
          <img :src="imageUrl('icons/icon-logo-2.svg')" width="48" alt="Logo" />
        </RouterLink>

        <ul class="mb-0 mt-4">
          <li
            :class="[
              'menu-item',
              activeLinkIndex == 0 ? 'active' : '',
              getActiveLink,
              !Object.values(menuLinks)[0].active ? 'disable' : '',
            ]"
            @click="showSubMenuFunc(0)"
            title="Дашборд"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 3.5C3.72386 3.5 3.5 3.72386 3.5 4V11C3.5 11.2761 3.72386 11.5 4 11.5H9C9.27614 11.5 9.5 11.2761 9.5 11V4C9.5 3.72386 9.27614 3.5 9 3.5H4ZM2.5 4C2.5 3.17157 3.17157 2.5 4 2.5H9C9.82843 2.5 10.5 3.17157 10.5 4V11C10.5 11.8284 9.82843 12.5 9 12.5H4C3.17157 12.5 2.5 11.8284 2.5 11V4ZM15 3.5C14.7239 3.5 14.5 3.72386 14.5 4V7C14.5 7.27614 14.7239 7.5 15 7.5H20C20.2761 7.5 20.5 7.27614 20.5 7V4C20.5 3.72386 20.2761 3.5 20 3.5H15ZM13.5 4C13.5 3.17157 14.1716 2.5 15 2.5H20C20.8284 2.5 21.5 3.17157 21.5 4V7C21.5 7.82843 20.8284 8.5 20 8.5H15C14.1716 8.5 13.5 7.82843 13.5 7V4ZM15 12.5C14.7239 12.5 14.5 12.7239 14.5 13V20C14.5 20.2761 14.7239 20.5 15 20.5H20C20.2761 20.5 20.5 20.2761 20.5 20V13C20.5 12.7239 20.2761 12.5 20 12.5H15ZM13.5 13C13.5 12.1716 14.1716 11.5 15 11.5H20C20.8284 11.5 21.5 12.1716 21.5 13V20C21.5 20.8284 20.8284 21.5 20 21.5H15C14.1716 21.5 13.5 20.8284 13.5 20V13ZM4 16.5C3.72386 16.5 3.5 16.7239 3.5 17V20C3.5 20.2761 3.72386 20.5 4 20.5H9C9.27614 20.5 9.5 20.2761 9.5 20V17C9.5 16.7239 9.27614 16.5 9 16.5H4ZM2.5 17C2.5 16.1716 3.17157 15.5 4 15.5H9C9.82843 15.5 10.5 16.1716 10.5 17V20C10.5 20.8284 9.82843 21.5 9 21.5H4C3.17157 21.5 2.5 20.8284 2.5 20V17Z"
                fill="#364263"
              />
            </svg>
          </li>
          <li
            :class="[
              'menu-item',
              activeLinkIndex == 1 ? 'active' : '',
              getActiveLink,
              !Object.values(menuLinks)[1].active ? 'disable' : '',
            ]"
            @click="showSubMenuFunc(1)"
            title="Станции"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.6324 3.72134C15.6323 3.7216 15.6325 3.72108 15.6324 3.72134L13.3613 9.81475H17.4254C18.0595 9.81475 18.5864 10.286 18.5864 10.9426C18.5864 11.2964 18.4461 11.5827 18.2497 11.8337L18.2444 11.8404L10.4714 21.4467C10.0489 21.9689 9.39848 22.1594 8.8605 21.8726C8.30674 21.5774 8.12676 20.9174 8.36718 20.2724L10.6388 14.1775H6.57471C5.94567 14.1775 5.4137 13.7193 5.4137 13.0497C5.4137 12.6967 5.55327 12.408 5.75662 12.1591C5.75695 12.1587 5.75728 12.1583 5.7576 12.1579L13.5288 2.55381C13.5288 2.5538 13.5288 2.55382 13.5288 2.55381C13.9514 2.03145 14.601 1.84185 15.1382 2.12614C15.6919 2.41914 15.874 3.07804 15.6324 3.72134ZM14.5649 3.06246C14.5207 3.07429 14.4422 3.11179 14.3537 3.22118L6.57885 12.8298C6.48409 12.9456 6.47478 13.005 6.47478 13.0497C6.47478 13.0687 6.47825 13.0791 6.48006 13.0833C6.48187 13.0876 6.48398 13.0905 6.48688 13.0933C6.49096 13.0971 6.51413 13.1164 6.57471 13.1164H12.1667L9.36145 20.643C9.30959 20.7821 9.32132 20.8626 9.33094 20.8941C9.34012 20.9242 9.35297 20.9327 9.35964 20.9363C9.36768 20.9405 9.39131 20.9497 9.4356 20.9379C9.47946 20.9261 9.55789 20.8888 9.64642 20.7794L17.4165 11.1766C17.5165 11.0481 17.5254 10.9866 17.5254 10.9426C17.5254 10.9272 17.5225 10.9181 17.5205 10.9134C17.5184 10.9085 17.5156 10.9043 17.5114 10.9003C17.5048 10.8941 17.4812 10.8758 17.4254 10.8758H11.8334L14.6387 3.34926L14.6393 3.34777C14.6905 3.21148 14.6786 3.13354 14.6695 3.10398C14.6608 3.07574 14.6488 3.06762 14.6419 3.064C14.6337 3.05965 14.6096 3.0505 14.5649 3.06246Z"
                fill="#364263"
              />
            </svg>
          </li>
          <li
            :class="[
              'menu-item',
              activeLinkIndex == 2 ? 'active' : '',
              getActiveLink,
              !Object.values(menuLinks)[2].active ? 'disable' : '',
            ]"
            @click="showSubMenuFunc(2)"
            title="Статистика"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 14V3C12.9125 3.01576 15.7013 4.17974 17.7608 6.23924C19.8203 8.29873 20.9842 11.0875 21 14C20.9989 16.5773 20.0929 19.0725 18.44 21.05L10 14Z"
                stroke="#364263"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.53 18.62C14.7322 19.5021 13.7282 20.1725 12.6077 20.5712C11.4872 20.9699 10.2853 21.0844 9.10964 20.9045C7.93401 20.7246 6.82137 20.2559 5.8714 19.5403C4.92142 18.8248 4.16379 17.8847 3.66636 16.8044C3.16894 15.7241 2.94727 14.5373 3.0212 13.3503C3.09514 12.1633 3.46237 11.0132 4.09 10.0029C4.71763 8.99271 5.58606 8.15395 6.61749 7.56181C7.64891 6.96966 8.81112 6.64262 9.99999 6.60999"
                stroke="#364263"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </li>
          <li
            :class="[
              'menu-item',
              activeLinkIndex == 3 ? 'active' : '',
              getActiveLink,
              !Object.values(menuLinks)[3].active ? 'disable' : '',
            ]"
            @click="showSubMenuFunc(3)"
            title="Партнеры"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.50415 6.46704C4.12344 6.46704 3.00415 7.58633 3.00415 8.96704C3.00415 10.3478 4.12344 11.467 5.50415 11.467C6.88486 11.467 8.00415 10.3478 8.00415 8.96704C8.00415 7.58633 6.88486 6.46704 5.50415 6.46704ZM2.00415 8.96704C2.00415 7.03404 3.57115 5.46704 5.50415 5.46704C7.43715 5.46704 9.00415 7.03404 9.00415 8.96704C9.00415 10.9 7.43715 12.467 5.50415 12.467C3.57115 12.467 2.00415 10.9 2.00415 8.96704Z"
                fill="#364263"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.5018 6.47095C17.1211 6.47095 16.0018 7.59024 16.0018 8.97095C16.0018 10.3517 17.1211 11.4709 18.5018 11.4709C19.8825 11.4709 21.0018 10.3517 21.0018 8.97095C21.0018 7.59024 19.8825 6.47095 18.5018 6.47095ZM15.0018 8.97095C15.0018 7.03795 16.5688 5.47095 18.5018 5.47095C20.4348 5.47095 22.0018 7.03795 22.0018 8.97095C22.0018 10.9039 20.4348 12.4709 18.5018 12.4709C16.5688 12.4709 15.0018 10.9039 15.0018 8.97095Z"
                fill="#364263"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 15.0295C10.6193 15.0295 9.5 16.1488 9.5 17.5295C9.5 18.9103 10.6193 20.0295 12 20.0295C13.3807 20.0295 14.5 18.9103 14.5 17.5295C14.5 16.1488 13.3807 15.0295 12 15.0295ZM8.5 17.5295C8.5 15.5965 10.067 14.0295 12 14.0295C13.933 14.0295 15.5 15.5965 15.5 17.5295C15.5 19.4625 13.933 21.0295 12 21.0295C10.067 21.0295 8.5 19.4625 8.5 17.5295Z"
                fill="#364263"
              />
              <path
                d="M4.54045 14.0684C4.40597 13.7392 4.71493 13.4123 5.06896 13.4463C5.25285 13.4639 5.41974 13.5796 5.49116 13.7499C5.95302 14.8518 6.68651 15.8116 7.60788 16.5456C7.78952 16.6903 7.85935 17.021 7.74216 17.2241C7.62959 17.4192 7.24204 17.5283 7.06412 17.3902C5.95608 16.5297 5.07871 15.3863 4.54045 14.0684Z"
                fill="#364263"
              />
              <path
                d="M16.3047 17.146C16.4258 17.4125 16.7467 17.5392 16.9768 17.3581C18.0641 16.5025 18.9257 15.3729 19.4574 14.0735C19.5921 13.7445 19.2838 13.4175 18.9299 13.4508C18.7458 13.4682 18.5785 13.5838 18.507 13.7543C18.0796 14.7736 17.414 15.6979 16.5917 16.4105C16.453 16.5307 16.3102 16.662 16.2748 16.8421C16.2567 16.9338 16.2568 17.0407 16.3047 17.146Z"
                fill="#364263"
              />
              <path
                d="M7.7347 5.05785C7.89515 5.14959 8.09264 5.1476 8.24909 5.04919C9.33559 4.36577 10.6216 3.97046 12 3.97046C13.3807 3.97046 14.6689 4.36716 15.7566 5.05279C15.9131 5.15145 16.1108 5.15354 16.2714 5.0617C16.5797 4.88543 16.6504 4.43821 16.3517 4.24608C15.0967 3.43879 13.603 2.97046 12 2.97046C10.3996 2.97046 8.90833 3.43718 7.65472 4.24193C7.35571 4.43388 7.42624 4.88147 7.7347 5.05785Z"
                fill="#364263"
              />
            </svg>
          </li>
          <li
            :class="[
              'menu-item',
              activeLinkIndex == 4 ? 'active' : '',
              getActiveLink,
              !Object.values(menuLinks)[4].active ? 'disable' : '',
            ]"
            @click="showSubMenuFunc(4)"
            title="Справочники"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 3.75C0 3.33579 0.335786 3 0.75 3H8.24687C9.81303 3 11.194 4.2861 12 5.5C12.806 4.28616 14.1838 3 15.75 3H23.25C23.6642 3 24 3.33579 24 3.75V18.2954C24 18.4952 23.9203 18.6867 23.7785 18.8275C23.6368 18.9683 23.4447 19.0467 23.2449 19.0454L15.5631 18.9934C14.7604 18.988 13.932 19.1385 13.3534 19.5105L12.432 20.1385C12.1391 20.4314 11.7249 20.4314 11.432 20.1385L10.5 19.5C9.93198 19.1385 9.24235 18.9828 8.4467 18.9828H0.75C0.335786 18.9828 0 18.6471 0 18.2328V3.75ZM11.5 7.5C11.4986 5.84413 9.40275 4 7.74687 4H1V18H7.9467C8.96997 18 10.7073 18.3687 11.5 19V7.5ZM12.5 19V7.5C12.5 5.84315 14.5931 4 16.25 4H23V18H16C14.9707 17.993 13.299 18.3676 12.5 19Z"
                fill="#364263"
              />
            </svg>
          </li>
          <li
            :class="
              'menu-item ' +
              (activeLinkIndex == 5 ? 'active' : '') +
              (Object.values(menuLinks)[5].active ? '' : ' disable')
            "
            @click="showSubMenuFunc(5)"
            title="Биллинг"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.05901 21.4811H22M6.07708 17.7793V9.63531M12 17.7793V9.63531M17.9229 17.7793V9.63531M2.13906 9.63531H21.8609C22.0087 9.63531 22.0653 9.4426 21.941 9.36268L12.0801 3.02352C12.0313 2.99216 11.9687 2.99216 11.9199 3.02352L2.05899 9.36268C1.93468 9.4426 1.99128 9.63531 2.13906 9.63531Z"
                stroke="#364263"
                stroke-linecap="round"
              />
            </svg>
          </li>
        </ul>
      </div>
      <div class="text-center">
        <ul class="mb-0">
          <li
            :class="
              'menu-item ' +
              (Object.values(menuLinks)[6].active ? '' : ' disable')
            "
            @click="showSubMenuFunc(6)"
            title="Система тех. поддержки"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 12.5007V19.5007C21 19.7659 20.8946 20.0202 20.7071 20.2078C20.5196 20.3953 20.2652 20.5007 20 20.5007H4C3.73478 20.5007 3.48043 20.3953 3.29289 20.2078C3.10536 20.0202 3 19.7659 3 19.5007V12.5007M21 12.5007H16C16 13.5615 15.5786 14.579 14.8284 15.3291C14.0783 16.0792 13.0609 16.5007 12 16.5007C10.9391 16.5007 9.92172 16.0792 9.17157 15.3291C8.42143 14.579 8 13.5615 8 12.5007H3M21 12.5007L18.23 4.18067C18.1629 3.98205 18.0351 3.80954 17.8646 3.68752C17.6942 3.56549 17.4896 3.50013 17.28 3.50067H16H8H6.72C6.50193 3.48929 6.28614 3.5496 6.10557 3.67239C5.925 3.79518 5.78958 3.97369 5.72 4.18067L3 12.5007"
                stroke="#364263"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </li>
          <li
            :class="
              'menu-item ' +
              getActiveLink +
              (Object.values(menuLinks)[7].active ? '' : ' disable')
            "
            @click="showSubMenuFunc(7)"
            title="Уведомления"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.5 5C7.10218 5 6.72064 5.15804 6.43934 5.43934C6.15804 5.72064 6 6.10218 6 6.5V18.5C6 18.8978 6.15804 19.2794 6.43934 19.5607C6.72064 19.842 7.10218 20 7.5 20H19.5C19.8978 20 20.2794 19.842 20.5607 19.5607C20.842 19.2794 21 18.8978 21 18.5V12.5C21 12.2239 21.2239 12 21.5 12C21.7761 12 22 12.2239 22 12.5V18.5C22 19.163 21.7366 19.7989 21.2678 20.2678C20.7989 20.7366 20.163 21 19.5 21H7.5C6.83696 21 6.20107 20.7366 5.73223 20.2678C5.26339 19.7989 5 19.163 5 18.5V6.5C5 5.83696 5.26339 5.20107 5.73223 4.73223C6.20107 4.26339 6.83696 4 7.5 4H13.5C13.7761 4 14 4.22386 14 4.5C14 4.77614 13.7761 5 13.5 5H7.5Z"
                fill="#364263"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 4C18.8954 4 18 4.89543 18 6C18 7.10457 18.8954 8 20 8C21.1046 8 22 7.10457 22 6C22 4.89543 21.1046 4 20 4ZM17 6C17 4.34315 18.3431 3 20 3C21.6569 3 23 4.34315 23 6C23 7.65685 21.6569 9 20 9C18.3431 9 17 7.65685 17 6Z"
                fill="#364263"
              />
            </svg>
          </li>
        </ul>
        <!--        <RouterLink :to="{name: 'user-profile'}" class="w-100">-->
        <!--          <b-avatar class="mt-2" text="SKY"/>-->
        <!--        </RouterLink>-->
      </div>
    </div>
    <Transition name="slide-fade">
      <div class="menu-sublist" v-if="showSubMenu">
        <div class="d-flex menu-sublist__header" style="padding: 20px 16px">
          <!-- <div class="label">{{ activeLabel }}</div> -->

          <div class="menu-btn-close" @click="showSubMenu = false">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 19L5 5"
                stroke="#364263"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19 5L5 19"
                stroke="#364263"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <ul class="sublist">
          <li
            v-for="(subMenuItem, subIndex) in computedSubMenuItems"
            :key="subIndex"
          >
            <router-link
              :to="subMenuItem.url"
              :class="
                'sublist-item-link ' +
                ($route.path === subMenuItem.url ? 'active' : '')
              "
              >{{ subMenuItem.label }}</router-link
            >
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const route = useRoute();
const store = useStore();
const roles = store.getters.user_role;

const menuLinks = reactive({
  dashbord: {
    active: true,
    urls: [{ active: true, label: "", url: "/dashboard" }],
  },
  stations: {
    label: "Станции",
    active: roles.find(
      (o) =>
        o.name === "Администратор" ||
        o.name === "Менеджер" ||
        o.name === "Техник"
    ),
    urls: [
      { active: true, label: "Станции", url: "/stations" },
      { active: true, label: "Сессии", url: "/stations/transactions" },
      { active: true, label: "Управление ЭЗС", url: "/stations/control" },
      {
        active: false,
        label: "Профили зарядки",
        url: "/stations/charge-profile",
      },
      { active: false, label: "Мониторинг", url: "/stations/monitoring" },
      {
        active: true,
        label: "Журнал статусов",
        url: "/stations/status-journal",
      },
    ],
  },
  statistics: {
    label: "Статистика",
    active: roles.find(
      (o) =>
        o.name === "Администратор" ||
        o.name === "Менеджер" ||
        o.name === "Корпоративный клиент"
    ),
    urls: [
      { active: false, label: "Обзор", url: "/statistics" },
      { active: true, label: "Потребление", url: "/statistics/consumption" },
      { active: true, label: "Сессии", url: "/statistics/sessions" },
      {
        active: false,
        label: "Продолжительность",
        url: "/statistics/duration",
      },
      {
        active: false,
        label: "Резирвирования",
        url: "/statistics/reservations",
      },
      { active: false, label: "Расчеты", url: "/statistics/calculations" },
    ],
  },
  partners: {
    label: "Партнеры",
    active: roles.find(
      (o) => o.name === "Администратор" || o.name === "Корпоративный клиент"
    ),
    urls: [
      { active: false, label: "Партнеры", url: "/users/partners" },
      { active: false, label: "Заявки на добавление", url: "/" },
      { active: false, label: "Сотрудники*", url: "/users/employees" },
      {
        active: roles.find((o) => o.name === "Администратор"),
        label: "Клиенты",
        url: "/users/clients",
      },
      {
        active: roles.find(
          (o) => o.name === "Корпоративный клиент" || o.name === "Администратор"
        ),
        label: "Клиенты",
        url: "/users/members",
      },
    ],
  },
  manufacturers: {
    active: false,
    urls: [
      { active: true, label: "Производители", url: "/" },
      { active: true, label: "Модели", url: "/" },
      { active: true, label: "Коннекторы", url: "/" },
      { active: true, label: "Единицы измерения", url: "/" },
      { active: true, label: "Роли", url: "/" },
    ],
  },
  billing: {
    active: false,
    urls: [{ active: true, label: "Биллинг", url: "/" }],
  },
  support: {
    active: false,
    urls: [{ active: true, label: "Система тех. поддержки", url: "/" }],
  },
  notification: {
    active: false,
    urls: [{ active: true, label: "Уведомления", url: "/" }],
  },
});

const showSubMenu = ref(false);
const subMenuItems = ref([]);
const activeLinkIndex = ref(0);
const activeLabel = ref("");

const closeSubMenu = () => {
  showSubMenu.value = false;
};

const showSubMenuFunc = (index) => {
  const mainMenu = Object.values(menuLinks)[index];
  const submenu = mainMenu.urls;
  if (mainMenu.active) {
    activeLinkIndex.value = index;
    activeLabel.value = mainMenu.label;
    if (submenu.length > 1) {
      subMenuItems.value = submenu;
      showSubMenu.value = true;
    } else {
      router.push(submenu[0].url).catch(() => {});
      closeSubMenu();
    }
  } else {
    showSubMenu.value = false;
  }
};

// Автоматически закрываем подменю, если путь изменяется
watchEffect(closeSubMenu);

const computedSubMenuItems = computed(() => {
  return subMenuItems.value.filter((i) => i.active);
});

// Вычисляем активный индекс ссылки на основе текущего пути
const activeLinkComputed = computed(() => {
  let foundIndex = 0;
  Object.values(menuLinks).forEach((menuCategory, index) => {
    if (menuCategory.urls) {
      const found = menuCategory.urls.some((menu) => menu.url === route.path);
      if (found) {
        foundIndex = index;
      }
    }
  });
  return foundIndex;
});

// Следим за изменениями вычисляемого свойства, чтобы установить активный индекс и метку
watchEffect(() => {
  activeLinkIndex.value = activeLinkComputed.value;
  const mainMenu = Object.values(menuLinks)[activeLinkIndex.value];
  activeLabel.value = mainMenu?.label;
});
</script>

<style scoped lang="scss">
.menu-wrap {
  position: relative;
  color: white;
  background-color: getColor(grayscale, gray-100);
  position: fixed;
  z-index: 9;
  .main-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80px;
    height: 100vh;
    //height: 100%;
    flex-shrink: 0;
    border-right: 1px dashed rgba(255, 255, 255, 0.08);
    padding: 16px 12px;
    .menu-item {
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin: 8px auto;
      svg {
        fill: none;
      }
      &.active,
      &:not(.disable):hover {
        background-color: rgba(255, 255, 255, 0.08);

        svg {
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
            brightness(108%) contrast(102%);
        }
      }
    }
  }
  .menu-sublist {
    z-index: 1000;
    width: 313px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: -313px;
    background-color: getColor(grayscale, gray-100);
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: uppercase;
    }
    .menu-btn-close {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #71747b;
      border-radius: 25px;
      cursor: pointer;
      svg {
        path {
          stroke: #ffffff;
        }
      }
    }
    .sublist {
      .sublist-item {
        &-link {
          display: block;
          width: 100%;
          border-left: 2px solid transparent;
          padding: 10px 30px;
          color: white;
          &.active,
          &:hover {
            border-left: 2px solid getColor(primary, primary-50);
            color: getColor(primary, primary-50);
          }
        }
      }
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
    li {
      cursor: pointer;
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }
  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(-20px);
    opacity: 0;
  }

  // Медиа-запросы для адаптации под мобильные устройства
  @media (max-width: 768px) {
    position: fixed;
    height: 100vh;
    z-index: 9;

    .main-menu {
      width: 60px;
      padding: 12px 8px;

      .menu-item {
        width: 48px;
        height: 48px;
        margin: 4px auto;
      }
    }

    .menu-sublist {
      // width: 100%;
      // right: -100%;

      &__header {
        padding: 16px;
      }

      .sublist-item-link {
        padding: 8px 20px;
      }
    }
  }

  @media (max-width: 480px) {
    .main-menu {
      padding: 8px 4px;

      .menu-item {
        height: 40px;
        margin: 2px auto;
      }
    }

    .menu-sublist {
      // width: 100%;
      // right: -100%;

      &__header {
        padding: 12px;
      }

      .sublist-item-link {
        padding: 6px 16px;
      }
    }
  }
}
</style>
