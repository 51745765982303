import axios from "axios";
import store from "../store";
import router from "../router";

const defaultConfig = {
  baseURL: store.getters.apiServer,
  timeout: 50000,
  headers: {
    "Content-Type": "application/json"
  },
};


export const DefaultAPIInstance = axios.create(defaultConfig);

DefaultAPIInstance.interceptors.request.use(
  config => {
    const tokenSid = store.getters.token_sid;
    if (tokenSid) {
      config.headers["X-Openerp-Session-Id"] = tokenSid;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


// // axios.get('https://odoo.elproect.ru/platform/corporateUser/members/statistics/dashboard?fromDate=&toDate=')
// axios.post('https://ai.rndl.ru/api/user/get-sms-code')
//   .then((response) => {
//     if (response.status === 200) {
//       // Обработка успешного ответа
//       console.log('Успешный ответ:', response.data);
//       return response.data;
//     } else {
//       // Обработка всех остальных статусов
//       console.error('Непредвиденный статус:', response.status);
//       return Promise.reject(new Error('Непредвиденный статус: ' + response.status));
//     }
//   })
//   .catch(error => {
//     if (error.response) {
//       // Сервер ответил с кодом состояния, отличным от 2xx
//       console.error('Error Response Data:', error.response.data);
//       console.error('Error Response Status:', error.response.status);
//       console.error('Error Response Headers:', error.response.headers);
//     } else if (error.request) {
//       // Запрос был сделан, но ответа не получено
//       console.error('Error Request:', error.request);
//     } else {
//       // Произошло что-то еще при настройке запроса, вызвавшее ошибку
//       console.error('Error Message:', error.message);
//     }
//   });

// // Перехватчик ответов
// DefaultAPIInstance.interceptors.response.use(
//   response => {
//     // Если ответ успешен (статус 2xx), возвращаем данные
//     return response;
//   },
//   error => {
//     console.log(error)
//     // Если ответ имеет ошибку
//     if (error.response) {
//       // Сервер ответил с кодом состояния, отличным от 2xx
//       if (error.response.status === 401) {
//         console.error('Unauthorized: Access is denied due to invalid credentials.');
//         // Здесь можно добавить логику для обработки неавторизованных запросов, например, редирект на страницу входа
//       } else {
//         console.error('Error Response Data:', error.response.data);
//         console.error('Error Response Status:', error.response.status);
//         console.error('Error Response Headers:', error.response.headers);
//       }
//     } else if (error.request) {
//       // Запрос был сделан, но ответа не получено
//       console.error('Error Request:', error.request);
//     } else {
//       // Произошло что-то еще при настройке запроса, вызвавшее ошибку
//       console.error('Error Message:', error.message);
//     }
//     return Promise.reject(error);
//   }
// );  


DefaultAPIInstance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      // Обработка успешного ответа
      console.log('Успешный ответ:', response);
      return response;
    } else {
      // Обработка всех остальных статусов
      console.error('Непредвиденный статус:', response.status);
      return Promise.reject(new Error('Непредвиденный статус: ' + response.status));
    }
    // return response;
  },
  (error) => {
    console.log(error)
    if (error.response) {
      if (error.response.status === 401) {
        console.log(401)
        store.dispatch("auth_logout").then(() => {
          router.push({ name: "login" });
        })
        .catch(err => {
          console.error("Error during logout:", err);
        });
      } else if (error.response.status === 403) {
        router.push({ name: "forbidden" });
      } else if (error.response.status === 0) {
        console.log("error.response.status === 0 :>> ", error);
        window.addEventListener("error", (event) => {
          console.error("Global error handler:", event);
          const error = event.error;
          if (error && error.response && error.response.status === 401) {
            store.dispatch("auth_logout").then(() => {
              router.push({ name: "login" });
            });
          }
        });
      }
    } else {
      console.log("Неизвестная ошибка без ответа от сервера :>> ", error);
      // Глобальный обработчик ошибок
      window.addEventListener("error", (event) => {
        console.error("Global error handler:", event);
        const error = event.error;
        console.log("Ошибки" + error, error.response, error.response.status)
        if (error && error.response && error.response.status === 401) {
          store.dispatch("auth_logout").then(() => {
            router.push({ name: "login" });
          });
        }
      });
    }
    return Promise.reject(error);
  }
);

export default DefaultAPIInstance;
