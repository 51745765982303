import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "@/api/index";
import { registerPlugins } from "@/plugins";
import { registerDirectives } from "@/plugins/directives";
import globalMixinPlugin from "@/helpers/globalMixin";

import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css"; /* Deprecated */
import "primeicons/primeicons.css";
import "./assets/scss/main.scss";

const app = createApp(App);

// Глобальные свойства
app.config.globalProperties.$api = api;
app.config.productionTip = false;

registerPlugins(app);
registerDirectives(app);

app.use(store).use(router).use(globalMixinPlugin);

app.mount("#app");
