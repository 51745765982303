<template>
  <div id="app">
    <MainView v-if="userLoggedIn" />
    <router-view v-else />
    <Toast position="bottom-left" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import Toast from "primevue/toast";
import MainView from "./views/MainView.vue";

const store = useStore();
const router = useRouter();

if (!store.getters.token_sid) {
  store.dispatch("auth_logout").then(() => {
    router.push({ name: "login" });
  });
}

const userLoggedIn = computed(() => store.getters.userLoggedIn);
</script>

<style lang="scss">
@import "bootstrap/dist/css/bootstrap.css";
@import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
</style>
