import { createStore } from "vuex";

export default createStore({
  state: {
    apiServer: `${process.env.VUE_APP_BACKEND_PROTOCOL}://${process.env.VUE_APP_BACKEND_HOST}/platform`,
    preloader: false,
    header: "Главная",
    auth: {
      logged: false,
      user: JSON.parse(localStorage.getItem("user")) || {
        session: {
          sid: "",
        },
      },
    },
    user_role: "",
    token: "",
    token_sid: localStorage.getItem("token_sid") || "",
  },
  getters: {
    userLoggedIn: (state) => {
      return !!state.token_sid;
    },
    apiServer: (state) => {
      return state.apiServer;
    },
    token_sid: (state) => {
      return state.token_sid;
    },
    preloader: (state) => {
      return state.preloader;
    },
    header: (state) => {
      return state.header;
    },
    user: (state) => {
      return state.auth.user;
    },
    user_role: (state) => {
      return state.auth.user.role;
    },
    count_stations: (state) => {
      return state.auth.user.count_stations;
    },
  },
  mutations: {
    setHeader(state, header) {
      state.header = header;
    },
    setPreloader(state, payload) {
      state.preloader = payload;
    },
    setUser(state, user) {
      console.log("mutation set user :>> ", user);
      state.user = user;
      state.token_sid = user.session.sid;

      localStorage.setItem("token_sid", state.token_sid);
      localStorage.setItem("user", JSON.stringify(user.user));
      localStorage.setItem("user_role", user.user_role);
    },
    // userLoggedIn (state, status) {
    //   state.userLoggedIn = status
    // },
    setUserRole(state, userRole) {
      state.auth.user.user_role = userRole;
    },
    auth_login(state, user) {
      state.auth.status = "success";
      state.auth.logged = true;
      // state.auth.token = auth.token
      state.auth.user = user;
      state.token_sid = user.session.sid;

      localStorage.setItem("token_sid", user.session.sid);
      localStorage.setItem("user", JSON.stringify(user));
      // localStorage.setItem('user_role', auth.user_role)
    },
    auth_refresh(state, token) {
      state.auth.token = token;
      state.auth.logged = true;
      localStorage.setItem("token", token);
    },
    auth_error(state) {
      state.auth.status = "error";
    },
    // eslint-disable-next-line no-unused-vars
    auth_logout(state, auth = null) {
      state.auth.user = null;
      state.auth.user_role = null;
      state.auth.logged = false;
      state.token_sid = "";

      localStorage.removeItem("token_sid");
      localStorage.removeItem("user");
      // localStorage.removeItem('user_role')
    },
  },
  actions: {
    setPreloader(context, payload) {
      context.commit("setPreloader", payload);
    },
    setHeader(context, header) {
      context.commit("setHeader", header);
    },
    setUser(context, user) {
      console.log("setUser :>> ", user);
      context.commit("setUser", user);
    },
    setUserLoggedIn(context, status) {
      context.commit("userLoggedIn", status);
    },
    setUserRole(context, role) {
      context.commit("setUserRole", role);
    },
    checkUserStatus({ dispatch }) {
      const token = localStorage.getItem("token_sid");
      if (token) {
        return dispatch("auth_refresh", token);
      } else {
        return Promise.resolve();
      }
    },

    auth_login({ commit }, auth) {
      // commit('auth_login', auth)
      return new Promise((resolve) => {
        commit("auth_login", auth);
        resolve();
      });
    },
    auth_refresh({ commit }, token) {
      return new Promise((resolve) => {
        commit("auth_refresh", token);
        resolve();
      });
    },
    auth_logout({ commit }, auth = false) {
      return new Promise((resolve) => {
        commit("auth_logout", auth);
        resolve();
      });
    },
  },
  modules: {},
});
