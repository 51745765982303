import { mapGetters } from "vuex";
import store from "@/store";

export default {
  install(app) {
    app.mixin({
      computed: {
        ...mapGetters([
          "apiServer",
          "preloader",
          "header",
          "user",
          "token_sid",
          "userLoggedIn",
        ]),
      },
      methods: {
        preloaderStart() {
          store.dispatch("setPreloader", true);
        },
        preloaderFinish() {
          store.dispatch("setPreloader", false);
        },
        encodeFile(file) {
          let Base64 = require("js-base64").Base64;
          return Base64.encode(file);
        },
        decodeImage(image) {
          let Base64 = require("js-base64").Base64;
          console.log("image :>> ", image);
          return Base64.decode(image);
        },
        getConnectorStatusColor(color, defValue = "status-secondary") {
          switch (color) {
            case 1:
              return "status-alert";
            case 2:
              return "status-secondary";
            case 10:
              return "status-success";
            default:
              return defValue;
          }
        },
        imageUrl(image) {
          return require(`@/assets/img/${image}`);
        },
        sendData(method = "POST", url, data, callback = null, update = true) {
          const options = {
            method: method,
            url: url,
            data: data,
          };
          this.$api(options)
            .then((response) => {
              if (response.status === 200 && update) {
                if (callback !== null) callback(response.data);
              }
            })
            .catch((error) => {
              console.log("send data response error", error);
            })
            .finally(() => {
              this.preloaderFinish();
            });
        },
        getData(url, callback = null) {
          const options = {
            method: "GET",
            url: url,
          };
          this.$api(options)
            .then((response) => {
              if (callback !== null) {
                callback(response.data);
              }
            })
            .catch((error) => {
              console.error(error.response);
            })
            .finally(() => {});
        },
      },
    });
  },
};
