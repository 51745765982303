import { createApp } from "vue";
import BootstrapVue3 from "bootstrap-vue-3";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import VueTheMask from "vue-the-mask";
import vClickOutside from "click-outside-vue3";
import moment from "moment";
import { russianLocale } from "@/helpers/russianLocale";

export function registerPlugins(app) {
  app.config.globalProperties.$moment = moment;

  app
    .use(BootstrapVue3)
    .use(PrimeVue, {
      locale: russianLocale,
    })
    .use(ToastService)
    .use(VueTheMask)
    .use(vClickOutside);
}
