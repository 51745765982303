import { DefaultAPIInstance } from "@/api/index";
export const authAPI = {
  logout() {
    return DefaultAPIInstance.post("/user/logout");
  },
  getSmsCode(data) {
    return DefaultAPIInstance.post("/user/get-sms-code", data);
  },
  login(data) {
    return DefaultAPIInstance.post("/user/login", data);
  },

  confirm(data) {
    return DefaultAPIInstance.post("/user/reset_password", data);
  },
  register(data) {
    return DefaultAPIInstance.post("/user/create-user", data);
  },
  registertest(data) {
    return DefaultAPIInstance.post("/user/request-to-create-user", data);
  },
};
